import * as ExperimentActions from '@redux/experiments';
import { directoryInlineRfqSelector } from '@redux/experiments/selectors/directory-inline-rfq';
import { vendorRecommendationDistanceSelector } from '@redux/experiments/selectors/vendor-recommendation-distance';
import { VRMAIPhotoSelector } from '@redux/experiments/selectors/vrm-ai-photo';
import { useAppDispatch } from '@redux/hooks';
import { receiveVendor } from '@redux/vendor';
import { setSimilarVendors as setSimilarVendorsAction } from '@redux/vrm/actions';
import { getSimilarVendors as getSimilarVendorsAction } from '@redux/vrm/thunks';
import { experiments, inboxUrl } from '@settings';
import { noop } from '@utils/noop';
import Spinner from '@xo-union/tk-component-spinner';
import React, {
	createContext,
	lazy,
	Suspense,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { connect } from 'react-redux';
import type { FCWithChildren } from 'types/react-extended';
import { afterNextPaint } from '../../../helpers/after-next-paint';
import Vendor from '../../decorators/vendor';
import { useInlineRfqForm } from '../../pages/Storefront/containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import VrmModals from '../../pages/VendorsSearch/containers/SearchResults/components/RFQModal/components/VrmModals/VrmModals';
import {
	MAX_VRM_VENDORS_DISPLAYED,
	filterVendors,
	selectUserMaxGuestCount,
} from '../../pages/VendorsSearch/containers/SearchResults/components/RFQModal/components/utils';
import { categoryHasMap } from '../../utils/categoryNameMappings';

const RFQModal = lazy(
	() =>
		import(
			/* webpackChunkName: "rfq" */ '../../pages/VendorsSearch/containers/SearchResults/components/RFQModal/components/RFQModal'
		),
);
const InlineRfqModal = lazy(
	() =>
		import(
			/* webpackChunkName: "inlineRfq" */ '../../pages/Storefront/containers/InlineRFQ/components/InlineRfqModal/InlineRfqModal'
		),
);

interface RFQModalContextPropsValue {
	handleCTAClick(vendor: Vendor.Raw, uiLocation?: string): void;
}

interface RFQModalContextProps {
	code: Category.CategoryCode;
	searchLocation: Redux.Location;
	searchPageLocation: Redux.SearchPageLocation;
	pageType: Redux.Page['pageType'];
	messagedVendors: MessagedVendors.Conversations;
	similarVendors: Vendor.Similar[];
	userMaxGuestCount?: string;
	getSimilarVendors: typeof getSimilarVendorsAction;
	setSimilarVendors: typeof setSimilarVendorsAction;
	vendorRecommendationDistanceAssignment?: string;
	vrmAIPhotoAssignment?: string;
	directoryInlineRfqAssignment?: string;
	reportServerSideExperiment: typeof ExperimentActions.reportServerSideExperiment;
}

const RFQModalContext = createContext<RFQModalContextPropsValue>({
	handleCTAClick: noop,
});

const ContextProvider: FCWithChildren<RFQModalContextProps> = (props) => {
	const {
		children,
		code,
		messagedVendors,
		directoryInlineRfqAssignment,
		similarVendors,
		vendorRecommendationDistanceAssignment,
		vrmAIPhotoAssignment,
		userMaxGuestCount,
		getSimilarVendors,
		setSimilarVendors,
		searchLocation,
		searchPageLocation,
		pageType,
		reportServerSideExperiment,
	} = props;
	const [vendorRaw, setVendorRaw] = useState<Vendor.Raw | null>(null);
	const [initiator, setRfqInitiator] = useState<string>('');
	const [shouldIncludeMap, setShouldIncludeMap] = useState(false);
	const [modal, setModal] = useState<string | null>(null);
	const dispatch = useAppDispatch();

	const recommendedVendors = useMemo(() => {
		return filterVendors(messagedVendors, similarVendors, userMaxGuestCount);
	}, [messagedVendors, similarVendors, userMaxGuestCount]);

	const inlineRfqFormContext = useInlineRfqForm({
		initiator: 'Vendor Card CTA',
		headerText: 'Message Vendor',
	});

	const handleCTAClick = (rawVendor: Vendor.Raw, uiLocation?: string) => {
		afterNextPaint(() => {
			if (categoryHasMap(code)) {
				setShouldIncludeMap(true);
			}
			const conversationId = messagedVendors[rawVendor.id];
			if (conversationId) {
				window.location.href = `${inboxUrl}/${conversationId}`;
				return;
			}

			setVendorRaw(rawVendor);
			setRfqInitiator(uiLocation || '');

			if (directoryInlineRfqAssignment) {
				// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
				console.log(
					'Directory Inline RFQ Assignment: ',
					directoryInlineRfqAssignment,
				);
				reportServerSideExperiment(experiments.directoryInlineRfq);

				if (directoryInlineRfqAssignment === 'variant1') {
					dispatch(receiveVendor({ data: { storefront: rawVendor } }));
					inlineRfqFormContext.openModal(new Vendor(rawVendor), rawVendor);
				}
			}

			const radius =
				vendorRecommendationDistanceAssignment === '70-miles' ? 70 : 25;

			const vendorsRequested = MAX_VRM_VENDORS_DISPLAYED;

			if (vrmAIPhotoAssignment) {
				// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
				console.log('VRM Smart Main Image: ', vrmAIPhotoAssignment);
				reportServerSideExperiment(experiments.VRMAIPhoto);
			}

			rawVendor &&
				getSimilarVendors(
					rawVendor,
					vendorsRequested,
					radius,
					searchLocation,
					searchPageLocation,
					pageType,
					vrmAIPhotoAssignment || undefined,
				);
		});
	};

	useEffect(() => {
		if (!modal) {
			setVendorRaw(null);
			setSimilarVendors([]);
		}
	}, [modal, setSimilarVendors]);

	const handleSubmittedInlineRfq = () => {
		setModal('vrm');
	};

	const closeRfqModal = () => {
		setModal(null);
		setVendorRaw(null);
	};

	const toggleVRM = (status: boolean) => {
		if (status) {
			setModal('vrm');
		}
		closeRfqModal();
	};

	const value = {
		handleCTAClick,
		shouldIncludeMap,
	};

	return (
		<RFQModalContext.Provider value={value}>
			{children}
			{vendorRaw !== null && (
				<Suspense fallback={<Spinner />}>
					{directoryInlineRfqAssignment === 'variant1' ? (
						<>
							<InlineRfqModal
								context={inlineRfqFormContext}
								handleCloseInlineRfq={closeRfqModal}
								handleSubmittedInlineRfq={handleSubmittedInlineRfq}
							/>
							<VrmModals
								initiator="Vendor Card CTA"
								modal={modal}
								// @ts-expect-error recommendedVendors: Type 'Similar[]' is not assignable to type 'FESharedProfile[]'.
								recommendedVendors={recommendedVendors}
								rfqType="directory"
								setModal={setModal}
								vendorRaw={vendorRaw}
							/>
						</>
					) : (
						<RFQModal
							initiator={initiator}
							isOpen
							onClose={closeRfqModal}
							vendorRaw={vendorRaw}
							modal={modal}
							setModal={setModal}
							toggleVRM={toggleVRM}
							recommendedVendors={recommendedVendors}
						/>
					)}
				</Suspense>
			)}
		</RFQModalContext.Provider>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	code: state.category.code,
	messagedVendors: state.messagedVendors.conversations,
	similarVendors: state.vrm.similarVendors,
	userMaxGuestCount: selectUserMaxGuestCount(state),
	searchLocation: state.location, // location for search page
	searchPageLocation: state.searchPageLocation, // location for referred search page on storefront
	pageType: state.page.pageType,
	vendorRecommendationDistanceAssignment:
		vendorRecommendationDistanceSelector(state),
	vrmAIPhotoAssignment: VRMAIPhotoSelector(state),
	directoryInlineRfqAssignment: directoryInlineRfqSelector(state),
});

const mapDispatchToProps = {
	reportServerSideExperiment: ExperimentActions.reportServerSideExperiment,
	getSimilarVendors: getSimilarVendorsAction,
	setSimilarVendors: setSimilarVendorsAction,
};

const RFQModalContextProvider = connect(
	mapStateToProps,
	mapDispatchToProps,
)(ContextProvider);

export { RFQModalContextProvider };

export default RFQModalContext;
