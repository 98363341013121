import React from 'react';
import type { InlineRfqSourceContent } from 'types/sourceContent';
import InlineRfqModal from '../../pages/Storefront/containers/InlineRFQ/components/InlineRfqModal/InlineRfqModal';
import { useInlineRfqForm } from '../../pages/Storefront/containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import RFQButton from '../../pages/Storefront/containers/cta_box/RfqButton';

export const PERSONALIZED_QUOTE_HEADER_TEXT = 'Get a personalized quote';

interface Props {
	sourceContent?: InlineRfqSourceContent;
}

export const PricingCta = ({ sourceContent = 'Pricing CTA' }: Props) => {
	const formContext = useInlineRfqForm({
		initiator: sourceContent,
		headerText: PERSONALIZED_QUOTE_HEADER_TEXT,
	});

	return (
		<div>
			<InlineRfqModal context={formContext} />
			<RFQButton
				type="message"
				color="secondary"
				size="md"
				label="Get a personalized quote"
				handleCTAClick={formContext.openModal}
				testId="pricing-cta-personalized-quote"
			/>
		</div>
	);
};
